.container {
  display: flex;
  flex-direction: row;
}

.containerTable {
  flex-grow: 1;
  padding: 3;
}

.content {
  margin: 20px;
  padding: 20px;
}
.toolbar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.filter {
  display: flex;
  justify-content: flex-start;
}

.input {
  display: none;
}

.buttonModal {
  display: flex;
  justify-content: space-between;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
