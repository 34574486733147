.container {
  display: flex;
  flex-direction: row;
}

.containerTable {
  flex-grow: 1;
  padding: 3;
}

.addFab {
  bottom: 25px;
  padding: 25px;
  position: fixed;
  right: 25px;
  float: right;
}

.delFab {
  bottom: 25px;
  padding: 25px;
  position: fixed;
  float: left;
}

.centerDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}